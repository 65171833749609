<template>
  <v-expansion-panels focusable>
      <v-expansion-panel
          v-for="(comments,index) in disapprovedComments"
          :key="index"
      >
      <v-expansion-panel-header v-if="comments.type === 'Onboarding Return' && onboarding">  
          <h3>{{ comments.user }}</h3>
          <!--Displaying What Type of Comment it is-->
          <h5 style="font-size: 0.8em;">{{ comments.type }}</h5>
          </v-expansion-panel-header>
          <v-expansion-panel-header v-else-if="comments.type === 'Validation Return' && validation">  
          <h3>{{ comments.user }}</h3>
          <h5 style="font-size: 0.8em;">{{ comments.type }}</h5>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-3 mx-2">
              <div>
                  <!--Displaying Date when it is commented-->
                  <span style="font-size: 0.8em; color: gray;">{{ formatTimestamp(comments.datetime) }}</span>
              </div>
              <div>
                  <strong>{{ comments.comment }}</strong>
              </div>

          </v-expansion-panel-content>
      </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

export default{
  props: {
    onboarding: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Boolean,
      required: true,
    },
   
  },
  data() {  
    return {
      tab: 1,
      disapprovedComments: [],
  }
  },
  async created () {
    try {
        const res = await this.$store.dispatch('appHistory/doGetComments', this.$route.params.id);
        this.disapprovedComments = res.data.result.logs
      } catch (error) {
        console.error('Failed to fetch comments:', error);
      }     
    },
  methods: {
      //Format Time Stamp to get time MM/DD/YYYY
      formatTimestamp(dateTimeString) {
          const date = new Date(dateTimeString);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
      }
  }
}
</script>
