<template>
    <v-expansion-panels focusable>
        <v-expansion-panel
            v-for="(comments,index) in comment "
            :key="index"
        >
            <v-expansion-panel-header >  
            <h3>{{ comments.name }}</h3>
            <!--Displaying What Type of Comment it is-->
            <h5 style="font-size: 0.8em;"> {{ comments.type }}</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-3 mx-2">
                <div>
                    <!--Displaying Date when it is commented-->
                    <span style="font-size: 0.8em; color: gray;">{{ formatTimestamp(comments.timestamp) }}</span>
                </div>
                <div>
                    <strong>{{ comments.comment }}</strong>
                </div>

            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'


export default{
    data() {
        return	{
            comment: []
        };
	},
    computed:{
        ...mapGetters({
            returnedEvaComments: 'salesProspecting/returnedEvaComments'
        })
    },
    mounted(){
        //Check if the returned is not yet completed by the encoder
        if (this.returnedEvaComments != null){
            this.comment = this.returnedEvaComments.filter(item => item.type && item.status == "pending");
        }
    },
    methods: {
        //Format Time Stamp to get time MM/DD/YYYY
        formatTimestamp(dateTimeString) {
            const date = new Date(dateTimeString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    }
}
</script>
